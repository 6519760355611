/**
 * Module dependencies.
 */

import { Element } from 'react-scroll';
import { Text, baseFontSize, clampCalc } from 'src/components/core/text';
import { media } from 'src/styles/media';
import { normalizeNewLine } from 'src/core/utils/string';
import Container from 'src/components/core/layout/container';
import FadeInAnimation from 'src/components/core/animations/fade-in';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import Image from 'src/components/core/image';
import RawHtml from 'src/components/core/raw-html';
import Svg from 'src/components/core/svg';
import quoteMark from 'src/assets/svg/quote-mark.svg';
import styled from 'styled-components';
import useBreakpoint from 'src/hooks/use-breakpoint';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  name: string;
};

/**
 * `Section` styled component.
 */

const Section = styled(Element).attrs({ as: 'section' })<Props>`
  background-color: var(--color-dark);
  padding-bottom: 6.875rem;
  position: relative;

  ${media.min.md`
    padding-bottom: 8.75rem;
    padding-top: 8.5rem;
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  ${media.min.md`
    display: grid;
    grid-template-areas: '. quote';
    grid-template-columns: repeat(2, 6fr);
  `}

  ${media.min.lg`
    grid-template-areas: '. quote .';
    grid-template-columns: 7fr 4fr 1fr;
 `}
`;

/**
 * `SvgQuoteBefore` styled component.
 */

const SvgQuoteBefore = styled(Svg)`
  color: var(--color-white);
  left: 0;
  opacity: 0.09;
  position: absolute;
  top: -1rem;

  ${media.min.md`
    left: -4.5rem;
    top: 0;
  `}
`;

/**
 * `SvgQuoteAfter` styled component.
 */

const SvgQuoteAfter = styled(SvgQuoteBefore)`
  bottom: 0.75rem;
  left: auto;
  right: 0;
  top: auto;
  transform: rotate(180deg);

  ${media.min.md`
    bottom: -0.25rem;
    right: 15%;
  `}
`;

/**
 * `QuoteWrapper` styled component.
 */

const QuoteWrapper = styled.div`
  padding-top: 4.75rem;
  position: relative;

  ${media.min.md`
    grid-area: quote;
  `}
`;

/**
 * `TextStyle` styled component.
 */

const TextStyle = styled(Text).attrs({ as: 'p', variant: 'quoteBlock' })`
  color: var(--color-white);
  font-size: clamp(${25 / baseFontSize}rem, ${clampCalc(25, 35)}, ${35 / baseFontSize}rem);
  line-height: clamp(${35 / baseFontSize}rem, ${clampCalc(35, 45)}, ${45 / baseFontSize}rem);
  margin: 0;
`;

/**
 * `Label` styled component.
 */

const Label = styled.p`
  color: var(--color-white);
  font-family: var(--font-futura-pt);
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.17rem;
  padding-top: 2.5rem;
  text-transform: uppercase;
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  ${media.min.md`
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  `}

  ${media.max.md`
    margin-bottom: 5.75rem;
  `}
`;

/**
 * `ImageStyle` styled component.
 */

const ImageStyle = styled(Image)`
  ${media.max.xl`
    left: -40% !important;
  `}

  ${media.max.lg`
    left: -50% !important;
    margin-bottom: 3.125rem;
  `}

  ${media.max.md`
    left: 0 !important;
  `}
`;

/**
 * `SaramagoQuoteSection` component.
 */

const SaramagoQuoteSection = ({ name }: Props) => {
  const { translate } = useTranslate();
  const isMobile = useBreakpoint('md', 'max');

  return (
    <Section name={name}>
      <FadeInAnimation options={{ transitionTime: 1 }}>
        <ImageWrapper>
          <ImageStyle
            alt={'José Saramago'}
            layout={isMobile ? 'intrinsic' : 'fill'}
            objectFit={'contain'}
            objectPosition={'left center'}
            src={'/images/saramago-quote.jpg'}
            {...(isMobile && {
              height: 1436,
              width: 1920
            })}
          />
        </ImageWrapper>
      </FadeInAnimation>

      <Container>
        <Grid>
          <QuoteWrapper>
            <FadeInUpAnimation
              options={{
                distance: '50%',
                transitionTime: 1
              }}
            >
              <SvgQuoteBefore icon={quoteMark} size={isMobile ? '4.875rem' : '6.25rem'} />

              <RawHtml element={TextStyle}>
                {normalizeNewLine(translate('homepage:sections.saramagoQuoteSection.description'))}
              </RawHtml>

              <Label>{translate('homepage:sections.saramagoQuoteSection.label')}</Label>

              <SvgQuoteAfter icon={quoteMark} size={isMobile ? '4.875rem' : '6.25rem'} />
            </FadeInUpAnimation>
          </QuoteWrapper>
        </Grid>
      </Container>
    </Section>
  );
};

/**
 * Export `SaramagoQuoteSection` component.
 */

export default SaramagoQuoteSection;
