/**
 * Export `homepageSections`.
 */

export const homepageSections = {
  welcome: 'welcome',
  saramago: 'saramago', // eslint-disable-line sort-keys
  tripToPortugalBook: 'tripToPortugalBook',
  tripToPortugal: 'tripToPortugal', // eslint-disable-line sort-keys
  bioPeixoto: 'bioPeixoto', // eslint-disable-line sort-keys
  // writersPlaces: 'writersPlaces',
  // theNewBook: 'theNewBook', // eslint-disable-line sort-keys
  saramagoQuote: 'saramagoQuote' // eslint-disable-line sort-keys
};

/**
 * Export `HomepageSection` type.
 */

export type HomepageSection = keyof typeof homepageSections;
