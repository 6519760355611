/**
 * Module dependencies.
 */

import { Element } from 'react-scroll';
import { Text, clampCalc } from 'src/components/core/text';
import { media } from 'src/styles/media';
import { normalizeNewLine } from 'src/core/utils/string';
import Container from 'src/components/core/layout/container';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import Image from 'src/components/core/image';
import Parallax from 'src/components/core/animations/parallax';
import RawHtml from 'src/components/core/raw-html';
import styled from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  name: string;
};

/**
 * `Section` styled component.
 */

const Section = styled(Element).attrs({ as: 'section' })<Props>`
  background-color: var(--color-grey-100);
  padding: clamp(40px, ${clampCalc(40, 107)}, 107px) 0;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  ${media.min.md`
    align-items: center;
    display: grid;
    grid-template-areas: '. picture . content .';
    grid-template-columns: 1fr 4fr 1fr 4fr 1fr;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  ${media.min.md`
    grid-area: content;
  `}
`;

/**
 * `TitleText` styled component.
 */

const TitleText = styled(Text)`
  color: var(--color-dark);
  font-size: clamp(55px, ${clampCalc(55, 65)}, 65px);
  letter-spacing: clamp(8.25px, ${clampCalc(8.25, 9.75)}, 9.75px);
  line-height: clamp(55px, ${clampCalc(55, 65)}, 65px);
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;

  ${media.min.md`
    text-align: left;
  `}

  @media screen and (max-width: 374px) {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
`;

/**
 * `Picture` styled component.
 */

const Picture = styled.div`
  padding-bottom: 119%;
  position: relative;

  ${media.min.md`
    grid-area: picture;
  `}
`;

/**
 * `FrameWrapper` styled component.
 */

const FrameWrapper = styled.div`
  height: 72%;
  left: 8%;
  position: absolute;
  top: 9%;
  width: 84%;
`;

/**
 * `Frame` styled component.
 */

const Frame = styled.div`
  background: var(--color-dark);
  border-radius: 50%;
  height: 100%;
`;

/**
 * `<RawHtml element={` styled component.
 */

const ParagraphText = styled(Text).attrs({
  as: 'p',
  variant: 'paragraph'
})`
  color: var(--color-grey-500);
  font-size: clamp(16px, ${clampCalc(16, 18)}, 18px);
  line-height: clamp(20px, ${clampCalc(20, 25)}, 25px);
`;

/**
 * Export `TripToPortugalBookSection` component.
 */

const TripToPortugalBookSection = ({ name }: Props) => {
  const { locale, translate } = useTranslate();

  return (
    <>
      <Section name={name}>
        <Container>
          <Grid>
            <Picture>
              <FrameWrapper>
                <Parallax fullType={'fullHeight'} speed={3} speedMobile={2}>
                  <Frame />
                </Parallax>
              </FrameWrapper>

              <Parallax fullType={'fullAbsolute'} speed={1}>
                <Image
                  alt={translate(`homepage:sections.tripToPortugalBookSection.title`)}
                  layout={'fill'}
                  objectFit={'contain'}
                  src={`/images/book-${locale}.png`}
                />
              </Parallax>
            </Picture>

            <Content>
              <FadeInUpAnimation
                options={{
                  distance: '100%',
                  transitionTime: 1
                }}
              >
                <TitleText as={'h2'} variant={'titleS'}>
                  {translate(`homepage:sections.tripToPortugalBookSection.title`)}
                </TitleText>
              </FadeInUpAnimation>

              <FadeInUpAnimation
                options={{
                  distance: '100%',
                  transitionTime: 1
                }}
              >
                <RawHtml element={ParagraphText}>
                  {normalizeNewLine(translate(`homepage:sections.tripToPortugalBookSection.description`))}
                </RawHtml>
              </FadeInUpAnimation>
            </Content>
          </Grid>
        </Container>
      </Section>
    </>
  );
};

/**
 * Export `TripToPortugalBookSection` component.
 */

export default TripToPortugalBookSection;
