/**
 * Module dependencies.
 */

import { Element } from 'react-scroll';
import { Text, clampCalc } from 'src/components/core/text';
import { media } from 'src/styles/media';
import { useLocalizedRoute } from 'src/core/utils/routes';
import Container from 'src/components/core/layout/container';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import Image from 'src/components/core/image';
import Parallax from 'src/components/core/animations/parallax';
import RawHtml from 'src/components/core/raw-html';
import UnderlineLink from 'src/components/core/links/underline-link';
import styled from 'styled-components';
import useBreakpoint from 'src/hooks/use-breakpoint';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  id?: string;
  name: string;
};

/**
 * `Section` styled component.
 */

const Section = styled(Element).attrs({ as: 'section' })<Props>`
  background-color: var(--color-grey-100);
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  align-items: center;
  display: grid;
  grid-template-areas:
    'header header'
    'title title'
    'subTitle subHeader'
    'leftText leftText'
    'picture picture'
    'rightText rightText';
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  padding: clamp(40px, ${clampCalc(40, 107)}, 107px) 0;

  ${media.min.md`
    grid-template-areas:
      'header   header   header'
      'title    title    title'
      '.        subTitle subHeader'
      'leftText picture  rightText';
    grid-template-columns: 3fr 6fr 3fr;
  `}
`;

/**
 * `HeaderText` styled component.
 */

const HeaderText = styled(Text)`
  color: var(--color-dark);
  font-size: clamp(16px, ${clampCalc(16, 25)}, 25px);
  letter-spacing: clamp(7.2px, ${clampCalc(7.2, 11.25)}, 11.25px);
  line-height: clamp(16px, ${clampCalc(16, 25)}, 25px);
  text-align: center;
  text-transform: uppercase;
`;

/**
 * `TitleText` styled component.
 */

const TitleText = styled(Text)`
  color: var(--color-dark);
  font-size: clamp(52px, ${clampCalc(52, 175)}, 175px);
  letter-spacing: clamp(2.75px, ${clampCalc(2.75, 7.75)}, 7.75);
  line-height: clamp(52px, ${clampCalc(52, 175)}, 175px);
  margin-bottom: -1rem;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: 320px) {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
`;

/**
 * `SubTitleTextWrapper` styled component.
 */

const SubTitleTextWrapper = styled.div`
  grid-area: subTitle;
  z-index: 1;

  ${media.max.md`
    grid-column: 1/3
  `}
`;

/**
 * `SubTitleText` styled component.
 */

const SubTitleText = styled(Text)`
  color: var(--color-yellow);
  font-size: clamp(45px, ${clampCalc(45, 125)}, 125px);
  line-height: clamp(45px, ${clampCalc(45, 125)}, 125px);
  text-align: center;
`;

/**
 * `SubHeaderText` styled component.
 */

const SubHeaderText = styled(Text)`
  color: var(--color-dark);
  font-size: clamp(14px, ${clampCalc(14, 32)}, 32px);
  font-style: italic;
  line-height: clamp(14px, ${clampCalc(14, 32)}, 32px);
  margin-bottom: 0;

  ${media.max.md`
    text-align: right;
    white-space: pre-wrap;
  `}
`;

/**
 * `Picture` styled component.
 */

const Picture = styled.div`
  grid-area: picture;
  height: 0;
  padding-bottom: 107%;
  position: relative;

  ${media.max.md`
    padding-bottom: 125%;
  `}
`;

/**
 * `FrameWrapper` styled component.
 */

const FrameWrapper = styled.div`
  bottom: 0;
  left: 10%;
  position: absolute;
  right: 10%;
  top: 20%;

  ${media.max.md`
    bottom: 0;
    left: 0%;
    position: absolute;
    right: 0%;
    top: 14%;
  `}
`;

/**
 * `Frame` styled component.
 */

const Frame = styled.div`
  border: 30px solid var(--color-dark);
  height: 100%;

  ${media.max.md`
    border: 20px solid var(--color-dark);
  `}
`;

/**
 * `ParagraphText` styled component.
 */

const ParagraphText = styled(Text).attrs({
  as: 'p',
  variant: 'paragraph'
})`
  color: var(--color-grey-500);
  font-size: clamp(16px, ${clampCalc(16, 18)}, 18px);

  ${media.max.md`
    padding-top: 3rem;
  `}
`;

/**
 * `KnowMoreAction` styled component.
 */

const KnowMoreAction = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  grid-area: rightText;

  ${media.min.md`
    margin-bottom: 5.25rem;
    max-width: 22.625rem;
    padding-top: 0.625rem;
  `}
`;

/**
 * Export `SaramagoSection` component.
 */

const SaramagoSection = ({ id, name }: Props) => {
  const { translate } = useTranslate();
  const isMobile = useBreakpoint('md', 'max');
  const routeResolver = useLocalizedRoute();

  return (
    <Section id={id} name={name}>
      <Container>
        <Grid>
          <div style={{ gridArea: 'header' }}>
            <FadeInUpAnimation
              options={{
                distance: '100%',
                transitionTime: 1
              }}
            >
              <HeaderText as={'p'} variant={'header'}>
                {translate(`homepage:sections.saramagoSection.intro`)}
              </HeaderText>
            </FadeInUpAnimation>
          </div>

          <div style={{ gridArea: 'title' }}>
            <FadeInUpAnimation
              options={{
                distance: '100%',
                transitionTime: 1
              }}
            >
              <TitleText as={'h2'} variant={'titleL'}>
                {translate(`homepage:sections.saramagoSection.title`)}
              </TitleText>
            </FadeInUpAnimation>
          </div>

          <SubTitleTextWrapper>
            <FadeInUpAnimation
              options={{
                distance: '100%',
                transitionTime: 1
              }}
            >
              <SubTitleText as={'p'} variant={'handwrittenM'}>
                {translate(`homepage:sections.saramagoSection.subTitle`)}
              </SubTitleText>
            </FadeInUpAnimation>
          </SubTitleTextWrapper>

          <div style={{ gridArea: 'subHeader' }}>
            <FadeInUpAnimation
              options={{
                distance: '100%',
                transitionTime: 1
              }}
            >
              <SubHeaderText as={'p'} variant={'quote'}>
                {isMobile
                  ? translate(`homepage:sections.saramagoSection.year`).replace('-', '\n')
                  : translate(`homepage:sections.saramagoSection.year`)}
              </SubHeaderText>
            </FadeInUpAnimation>
          </div>

          <div style={{ gridArea: 'leftText' }}>
            <FadeInUpAnimation
              options={{
                distance: '100%',
                transitionTime: 1
              }}
            >
              <RawHtml element={ParagraphText}>{translate(`homepage:sections.saramagoSection.paragraphLeft`)}</RawHtml>
            </FadeInUpAnimation>
          </div>

          <Picture>
            <FrameWrapper>
              <Parallax fullType={'fullHeight'} speed={3} speedMobile={2}>
                <Frame />
              </Parallax>
            </FrameWrapper>

            <Parallax fullType={'fullAbsolute'} speed={1}>
              <Image alt={'saramago'} layout={'fill'} objectFit={'contain'} src={'/images/saramago-full.png'} />
            </Parallax>
          </Picture>

          <KnowMoreAction>
            <FadeInUpAnimation
              options={{
                distance: '100%',
                transitionTime: 1
              }}
            >
              <ParagraphText as={'p'} variant={'paragraph'}>
                {translate(`homepage:sections.saramagoSection.paragraphRight`)}
              </ParagraphText>
            </FadeInUpAnimation>

            <FadeInUpAnimation
              options={{
                distance: '100%',
                transitionDelay: 0.5,
                transitionTime: 1
              }}
            >
              <UnderlineLink href={routeResolver('saramagoBio')}>{translate(`common:actions.knowMore`)}</UnderlineLink>
            </FadeInUpAnimation>
          </KnowMoreAction>
        </Grid>
      </Container>
    </Section>
  );
};

/**
 * Export `SaramagoSection` component.
 */

export default SaramagoSection;
