/**
 * Module dependencies.
 */

import { ifProp, switchProp } from 'styled-tools';
import { isExternalUrl } from 'src/core/utils/url';
import React, {
  ElementType,
  FC,
  ReactElement,
  ReactNode,
  forwardRef
} from 'react';

import RouterLink from 'src/components/core/links/router-link';
import styled, { css } from 'styled-components';

/**
 * Export `ButtonProps` interface.
 */

export interface ButtonProps {
  as?: ElementType;
  children?: ReactNode;
  className?: string;
  colorTheme?: ColorTheme;
  disabled?: boolean;
  href?: string;
  isFullWidth?: boolean;
  onClick?: () => void;
  rel?: string;
  target?: string;
  type?: string;
  variant?: Variant;
}

/**
 * Export `ColorTheme` type.
 */

export type ColorTheme = 'primary' | 'secondary';

/**
 * Export `Variant` type.
 */

export type Variant = 'filled' | 'outlined';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.button.attrs<ButtonProps>(props => {
  const { as, href, type } = props;
  const isExternal = isExternalUrl(href);
  const element =
    as ||
    (href && !isExternal && RouterLink) ||
    (href && isExternal && 'a') ||
    'button';

  return {
    as: element,
    type: type || (element === 'button' ? 'button' : null)
  };
})`
  -webkit-tap-highlight-color: transparent;
  border: 1px solid;
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
  text-transform: uppercase;
  transition: var(--transition-default);
  transition-property: background-color, border-color, color, opacity, width;
  white-space: nowrap;

  ${switchProp('colorTheme', {
    primary: css`
      font-family: var(--font-futura-pt-bold);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 12px;
      line-height: 15px;
      min-height: 73px;
      padding: 27px 48px;

      ${switchProp('variant', {
    filled: css`
          background-color: var(--color-yellow);
          border-color: var(--color-yellow);
          color: var(--color-black);

          &:focus,
          &:hover {
            background-color: var(--color-black-opacity-40);
            border-color: var(--color-white);
            color: var(--color-white);
          }
        `,
    outlined: css`
          background-color: var(--color-black-opacity-40);
          border-color: var(--color-white);
          color: var(--color-white);

          &:focus,
          &:hover {
            background-color: var(--color-yellow);
            border-color: var(--color-yellow);
            color: var(--color-black);
          }
        `
  })}
    `,
    secondary: css`
      border-radius: 29px;
      font-family: var(--font-futura-pt);
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 3px;
      line-height: 12px;
      min-height: 54px;
      padding: 19px 43px;
      ${switchProp('variant', {
    filled: css`
          background-color: var(--color-dark);
          border-color: var(--color-dark);
          color: var(--color-white);

          &:focus,
          &:hover {
            background-color: transparent;
            border-color: var(--color-dark);
            color: var(--color-dark);
          }
        `,
    outlined: css`
          background-color: transparent;
          border-color: var(--color-dark);
          color: var(--color-dark);

          &:focus,
          &:hover {
            background-color: var(--color-dark);
            border-color: var(--color-dark);
            color: var(--color-white);
          }
        `
  })}
    `
  })}

  ${ifProp(
    'disabled',
    css`
      cursor: default;
      opacity: 0.7;
      pointer-events: none;
    `
  )}

  ${ifProp(
    'isFullWidth',
    css`
      justify-content: center;
      width: 100%;
    `,
    css`
      width: max-content;
    `
  )}
`;

/**
 * `Button` component.
 */

const Button: FC<ButtonProps> = forwardRef<any, ButtonProps>(
  (props: ButtonProps, ref: any): ReactElement => {
    const {
      children,
      colorTheme = 'primary',
      variant = 'filled',
      ...rest
    } = props;

    return (
      <Wrapper
        colorTheme={colorTheme}
        ref={ref}
        variant={variant}
        {...rest}
      >
        {children}
      </Wrapper>
    );
  }
);

/**
 * `Button` display name.
 */

Button.displayName = 'Button';

/**
 * Export `Button` component.
 */

export default Button;
