/**
 * Module dependencies.
 */

import { Link } from 'react-scroll';
import { NavbarTheme } from 'src/types/navbar';
import { homepageSections } from 'src/core/content-config/homepage';
import { switchProp } from 'styled-tools';
import { useNavbarTheme } from 'src/context/navbar-theme/context';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
};

/**
 * Set active color theme.
 */

const setActiveColorTheme = css`
  ${switchProp('colorTheme', {
    dark: css`
      background-color: var(--color-dark);
      color: var(--color-dark);
    `,
    light: css`
      background-color: var(--color-white);
      color: var(--color-white);
    `
  })}
`;

/**
 * `List` styled component.
 */

const List = styled.div<{ colorTheme: NavbarTheme }>`
  ${setActiveColorTheme}

  background-color: transparent;
  border: 1px solid;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  padding: 0.75rem 0.5rem;
  position: fixed;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled(Link)<{
  colorTheme: NavbarTheme;
}>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 1rem;
  justify-content: center;
  width: 1rem;

  &::after {
    ${setActiveColorTheme}

    content: '';
    height: 0.3125rem;
    width: 0.3125rem;
  }

  &.active {
    &::after {
      background-color: var(--color-yellow);
    }
  }
`;

/**
 * `NavigationIndicator` component.
 */

const NavigationIndicator = (props: Props) => {
  const { className } = props;
  const { theme } = useNavbarTheme();

  return (
    <List className={className} colorTheme={theme}>
      {Object.keys(homepageSections).map(section => (
        <ListItem
          colorTheme={theme}
          duration={1000}
          key={section}
          offset={-50}
          smooth={'easeOutQuad'}
          spy
          // @ts-expect-error url is a valid prop
          to={homepageSections[section]}
        />
      ))}
    </List>
  );
};

/**
 * Export `NavigationIndicator` component.
 */

export default NavigationIndicator;
