/**
 * Module dependencies.
 */

import { NextPage } from 'next';
import { homepageSections } from 'src/core/content-config/homepage';
import { useLocalizedRoute } from 'src/core/utils/routes';
import BioPeixotoSection from 'src/components/sections/bio-peixoto-section';
import Footer from 'src/components/footer';
import Metatags from 'src/components/core/metatags';
import NavigationIndicator from 'src/components/navigation-indicator';
import SaramagoQuoteSection from 'src/components/sections/saramago-quote-section';
import SaramagoSection from 'src/components/sections/saramago-section';
import TriggerNavbarTheme, {
  useInitialNavbarTheme
} from 'src/components/navbar/trigger-navbar-theme';

import TripToPortugal from 'src/components/sections/trip-to-portugal-section';
import TripToPortugalBookSection from 'src/components/sections/trip-to-portugal-book-section';
import WelcomeSection from 'src/components/sections/welcome-section';
import useBreakpoint from 'src/hooks/use-breakpoint';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Home` page.
 */

const Home: NextPage = () => {
  const { locale, translate } = useTranslate();

  const routeResolver = useLocalizedRoute();
  const isMobile = useBreakpoint('md', 'max');

  useInitialNavbarTheme('light');

  return (
    <>
      <Metatags />

      {!isMobile && <NavigationIndicator />}

      <TriggerNavbarTheme colorTheme={'light'} />

      <WelcomeSection name={homepageSections.welcome} />

      <TriggerNavbarTheme colorTheme={'dark'} />

      <SaramagoSection
        id={'saramago-section'}
        name={homepageSections.saramago}
      />

      <TripToPortugalBookSection name={homepageSections.tripToPortugalBook} />

      <TripToPortugal name={homepageSections.tripToPortugal} />

      <TriggerNavbarTheme colorTheme={'light'} />

      <BioPeixotoSection
        description={translate(`homepage:sections.peixotoSection.paragraph`)}
        imageTitle={translate(`homepage:sections.peixotoSection.altImage`)}
        imageUrl={`/images/peixoto-${locale}.png`}
        label={translate(`homepage:sections.peixotoSection.intro`)}
        linkLabel={translate(`common:actions.knowMore`)}
        linkUrl={routeResolver('peixotoBio')}
        name={homepageSections.bioPeixoto}
        subtitle={translate(`homepage:sections.peixotoSection.subTitle`)}
        title={translate(`homepage:sections.peixotoSection.title`)}
      />

      <SaramagoQuoteSection name={homepageSections.saramagoQuote} />

      <Footer />
    </>
  );
};

/**
 * Export `Home` page.
 */

export default Home;
