/**
 * Module dependencies.
 */

import { Element } from 'react-scroll';
import { Text, clampCalc } from 'src/components/core/text';
import { ifProp, switchProp } from 'styled-tools';
import { media } from 'src/styles/media';
import Container from 'src/components/core/layout/container';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import Image from 'src/components/core/image';
import Parallax from 'src/components/core/animations/parallax';
import RawHtml from 'src/components/core/raw-html';
import UnderlineLink from 'src/components/core/links/underline-link';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

/**
 * Export `ColorTheme` type.
 */

export type ColorTheme = 'dark' | 'light';

/**
 * `Props` type.
 */

type Props = {
  colorTheme?: ColorTheme;
  description?: string;
  imageTitle?: string;
  imageUrl: string;
  label: string;
  linkLabel?: string;
  linkUrl?: string;
  name: string;
  subtitle: string;
  title: string;
};

/**
 * `Section` styled component.
 */

const Section = styled(Element).attrs({ as: 'section' })<
  Pick<Props, 'name'> & {
    colorTheme?: ColorTheme;
  }
>`
  ${switchProp('colorTheme', {
    dark: css`
      background-color: var(--color-grey-100);
      color: var(--color-dark);
    `,
    light: css`
      background-color: var(--color-dark);
      color: var(--color-white);
    `
  })}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  padding-top: clamp(50px, ${clampCalc(50, 85)}, 85px);

  ${media.min.md`
    padding-top: 0;
    display: grid;
    grid-template-areas: 'content picture';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 5.31rem max-content;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-area: content;

  ${media.min.md`
    grid-row: 2 / -1;
    padding-top: 10%;
    margin-bottom: 3rem;
  `};

  ${media.max.md`
    padding: 1.25rem 0 7rem;
  `};
`;

/**
 * `HeaderText` styled component.
 */

const HeaderText = styled(Text)`
  font-size: clamp(16px, ${clampCalc(16, 25)}, 25px);
  letter-spacing: clamp(7.2px, ${clampCalc(7.2, 11.25)}, 11.25px);
  line-height: clamp(16px, ${clampCalc(16, 25)}, 25px);
  margin-bottom: 1rem;
  text-align: center;
  text-transform: uppercase;

  ${media.min.md`
    margin-bottom: 2rem;
    padding-left: calc((100vw - (var(--gutter-mobile) * 2)) / 12);
    text-align: left;
  `};

  ${media.min.lg`
    padding-left: calc((100vw - (var(--gutter) * 2)) / 12);
  `}

  @media screen and (min-width: 1824px) {
    padding-left: calc(var(--container-max-width) / 12);
  }
`;

/**
 * `TitleText` styled component.
 */

const TitleText = styled(Text)`
  font-size: clamp(52px, ${clampCalc(52, 155)}, 155px);
  letter-spacing: clamp(2.75px, ${clampCalc(2.75, 7.75)}, 7.75);
  line-height: clamp(52px, ${clampCalc(52, 155)}, 155px);
  margin-bottom: -1rem;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  ${media.min.md`
    text-align: left;
  `}
`;

/**
 * `SubTitleText` styled component.
 */

const SubTitleText = styled(Text)<{ hasMargin?: boolean }>`
  color: var(--color-yellow);
  display: block;
  font-size: clamp(45px, ${clampCalc(45, 125)}, 125px);
  line-height: clamp(55px, ${clampCalc(55, 125)}, 125px);
  margin-bottom: 0;
  text-align: center;
  text-transform: none;

  ${media.min.md`
    padding-left: calc((100vw - (var(--gutter-mobile) * 2)) / 12);
    text-align: left;
  `};

  ${media.min.lg`
    padding-left: calc((100vw - (var(--gutter) * 2)) / 12);
  `}

  @media screen and (min-width: 1824px) {
    padding-left: calc(var(--container-max-width) / 12);
  }

  ${ifProp(
    'hasMargin',
    css`
      margin-bottom: 3rem;

      ${media.min.md`
        margin-bottom: 9rem;
      `}
    `
  )}
`;

/**
 * `Picture` styled component.
 */

const Picture = styled.div<{ colorTheme?: ColorTheme }>`
  grid-area: picture;
  overflow: hidden;
  padding-bottom: 140%;
  position: relative;

  ${media.min.md`
    grid-row: 2 / -1;
    margin-left: auto;
    padding-bottom: 124%;
    width: 100%;
  `};

  ${media.min.xxl`
    max-width: calc(100% - 5.5rem);
  `};

  &::after {
    bottom: 0;
    content: '';
    height: 30%;
    left: 0;
    position: absolute;
    right: 0;

    ${switchProp('colorTheme', {
      dark: css`
        display: none;
      `,
      light: css`
        background: linear-gradient(180deg, transparent 0%, var(--color-dark) 100%) 0% 0%;
      `
    })}
  }
`;

/**
 * `FrameWrapper` styled component.
 */

const FrameWrapper = styled.div`
  bottom: 18%;
  left: 5%;
  position: absolute;
  right: 5%;
  top: 19%;
`;

/**
 * `Frame` styled component.
 */

const Frame = styled.div<{ colorTheme?: ColorTheme }>`
  border: clamp(16px, ${clampCalc(16, 30)}, 30px) solid;
  height: 100%;

  ${switchProp('colorTheme', {
    dark: css`
      border-color: var(--color-dark);
    `,
    light: css`
      border-color: var(--color-white);
    `
  })}
`;

/**
 * `ParagraphText` styled component.
 */

const ParagraphText = styled(Text).attrs({
  as: 'p',
  variant: 'paragraph'
})`
  color: var(--color-grey-500);
  font-size: clamp(16px, ${clampCalc(16, 18)}, 18px);
  margin-bottom: 2.5rem;
  max-width: 31.25rem;
  padding-top: 2.5rem;

  ${media.min.md`
    padding-left: calc((100vw - (var(--gutter-mobile) * 2)) / 12);
    padding-top: 10%;
    text-align: left;
  `};

  ${media.min.lg`
    padding-left: calc((100vw - (var(--gutter) * 2)) / 12);
  `}

  @media screen and (min-width: 1824px) {
    padding-left: calc(var(--container-max-width) / 12);
  }
`;

const Link = styled.div`
  ${media.min.md`
    padding-left: calc((100vw - (var(--gutter-mobile) * 2)) / 12);
    text-align: left;
  `};
`;

/**
 * Export `BioPeixotoSection` component.
 */

const BioPeixotoSection = (props: Props) => {
  const { colorTheme, description, imageTitle, imageUrl, label, linkLabel, linkUrl, name, subtitle, title } = props;

  return (
    <Section colorTheme={colorTheme ?? 'light'} name={name}>
      <Container>
        <Grid>
          <Picture colorTheme={colorTheme ?? 'light'}>
            <FrameWrapper>
              <Parallax fullType={'fullHeight'} speed={3} speedMobile={2}>
                <Frame colorTheme={colorTheme ?? 'light'} />
              </Parallax>
            </FrameWrapper>

            <Parallax fullType={'fullAbsolute'} speed={1}>
              <Image alt={imageTitle ?? title} layout={'fill'} objectFit={'contain'} src={imageUrl} />
            </Parallax>
          </Picture>

          <Content>
            <FadeInUpAnimation
              options={{
                distance: '100%',
                transitionTime: 1
              }}
            >
              <HeaderText as={'p'} variant={'header'}>
                {label}
              </HeaderText>
            </FadeInUpAnimation>

            <FadeInUpAnimation
              options={{
                distance: '50%',
                transitionTime: 1
              }}
            >
              <TitleText as={'h2'} variant={'titleL'}>
                {title}

                <SubTitleText as={'span'} hasMargin={isEmpty(description)} variant={'handwrittenM'}>
                  {subtitle}
                </SubTitleText>
              </TitleText>
            </FadeInUpAnimation>

            {description && (
              <FadeInUpAnimation
                options={{
                  distance: '100%',
                  transitionTime: 1
                }}
              >
                <RawHtml element={ParagraphText}>{description}</RawHtml>
              </FadeInUpAnimation>
            )}

            {linkLabel && linkUrl && (
              <FadeInUpAnimation
                options={{
                  distance: '100%',
                  transitionTime: 1
                }}
              >
                <Link>
                  <UnderlineLink colorTheme={'light'} href={linkUrl}>
                    {linkLabel}
                  </UnderlineLink>
                </Link>
              </FadeInUpAnimation>
            )}
          </Content>
        </Grid>
      </Container>
    </Section>
  );
};

/**
 * Export `BioPeixotoSection` component.
 */

export default BioPeixotoSection;
