/**
 * Module dependencies.
 */

import { Element } from 'react-scroll';
import { Text, clampCalc } from 'src/components/core/text';
import { media } from 'src/styles/media';
import { useCallback } from 'react';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import Button from 'src/components/core/buttons/button';
import Container from 'src/components/core/layout/container';
import FadeInAnimation from 'src/components/core/animations/fade-in';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import Image from 'src/components/core/image';
import Svg from 'src/components/core/svg';
import fundacaoSaramagoSvg from 'src/assets/svg/logo.svg';
import saramago100Svg from 'src/assets/svg/saramago100.svg';
import styled from 'styled-components';
import useBreakpoint from 'src/hooks/use-breakpoint';
import useTranslate from 'src/hooks/use-translate';
import visitPortugalSvg from 'src/assets/svg/visit-portugal.svg';

/**
 * `Props` type.
 */

type Props = {
  name: string;
};

/**
 * `Section` styled component.
 */

const Section = styled(Element).attrs({ as: 'section' })<Props>`
  color: var(--color-white);
  position: relative;
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  min-height: 100vh;
  position: relative;
  text-align: center;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  padding-top: 6.75rem;
`;

/**
 * `LogosWrapper` styled component.
 */

const LogosWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
  justify-content: center;
  margin-bottom: 2rem;

  ${media.min.md`
    flex-direction: row;
    grid-gap: 2.75rem;
    margin-bottom: 3.25rem;
  `}
`;

/**
 * `PreTitle` styled component.
 */

const PreTitle = styled.p`
  font-family: var(--font-futura-pt);
  font-size: clamp(14px, ${clampCalc(14, 16)}, 16px);
  letter-spacing: clamp(6.3px, ${clampCalc(6.3, 7.2)}, 7.2px);
  line-height: clamp(18px, ${clampCalc(18, 21)}, 21px);
  margin-bottom: 0;
  text-transform: uppercase;

  ${media.min.md`
    margin-bottom: 0.5rem;
  `}
`;

/**
 * `PreSubtitle` styled component.
 */

const PreSubtitle = styled.p`
  font-family: var(--font-futura-pt-bold);
  font-size: clamp(17px, ${clampCalc(17, 24)}, 24px);
  letter-spacing: clamp(4.25px, ${clampCalc(4.25, 10.8)}, 10.8px);
  line-height: clamp(22px, ${clampCalc(22, 19)}, 19px);
  margin-bottom: 1.75rem;
  text-transform: uppercase;

  ${media.min.md`
    margin-bottom: 2.5rem;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text)`
  margin-bottom: 5.25rem;
  position: relative;

  ${media.min.md`
    margin-bottom: 8rem;
  `}

  > span {
    display: block;
  }
`;

/**
 * `TitleCountry` styled component.
 */

const TitleCountry = styled.p`
  margin-top: -0.5rem;
  text-transform: uppercase;

  ${media.min.md`
    margin-top: -1rem;
  `}

  @media screen and (max-width: 374px) {
    font-size: 2.75rem;
  }
`;

/**
 * `TitleSmall` styled component.
 */

const TitleSmall = styled.p`
  font-family: var(--font-futura-pt-bold);
  font-size: clamp(25px, ${clampCalc(25, 40)}, 40px);
  letter-spacing: clamp(11.25px, ${clampCalc(11.25, 18)}, 18px);
  line-height: clamp(33px, ${clampCalc(33, 51)}, 51px);
  margin-bottom: 0;
  text-transform: uppercase;
`;

/**
 * `TitleHandWrite` styled component.
 */

const TitleHandWrite = styled(Text)`
  color: var(--color-yellow);
  margin-top: -0.5rem;
  z-index: 9;

  ${media.min.md`
    margin-top: -3.5rem;
  `}
`;

/**
 * `KnowMore` styled component.
 */

const KnowMore = styled.div`
  align-items: center;
  bottom: clamp(68px, ${clampCalc(68, 102)}, 102px);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  grid-gap: 1.5rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  &::after {
    background-color: var(--color-yellow);
    content: '';
    height: clamp(40px, ${clampCalc(40, 145)}, 145px);
    position: absolute;
    top: 2.5rem;
    width: 1px;
  }
`;

/**
 * `KnowMoreText` styled component.
 */

const KnowMoreText = styled.p`
  font-family: var(--font-futura-pt);
  font-size: 0.75rem;
  letter-spacing: 0.3375rem;
  line-height: 1rem;
  margin-bottom: 0;
  text-transform: uppercase;

  ${media.min.md`
    font-size: 0.6875rem;
    letter-spacing: 0.309375rem;
  `}
`;

/**
 * `TriggerClick` styled component.
 */

const TriggerClick = styled.button`
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: clamp(80px, ${clampCalc(80, 185)}, 185px);
  outline: none;
  position: absolute;
  width: 100%;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  margin-bottom: 8.625rem;

  ${media.max.ms`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `}
`;

/**
 * `WelcomeSection` component.
 */

const WelcomeSection = ({ name }: Props) => {
  const { translate } = useTranslate();
  const isMobile = useBreakpoint('ms', 'max');
  const isMobileSm = useBreakpoint('sm', 'max');
  const { isReady } = useRouter();
  const routeResolver = useLocalizedRoute();
  const handleClick = useCallback((id: string) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, []);

  return (
    <Section name={name}>
      {isReady && (
        <FadeInAnimation options={{ transitionTime: 1 }}>
          <Image
            alt={translate('homepage:sections.welcomeSection.imageTitle')}
            layout={'fill'}
            objectFit={'cover'}
            src={isMobile ? '/images/saramago-vertical.jpg' : '/images/saramago.jpg'}
          />
        </FadeInAnimation>
      )}

      <StyledContainer>
        <Content>
          {isReady && (
            <>
              <FadeInUpAnimation
                options={{
                  distance: '100%',
                  transitionDelay: 0.2,
                  transitionTime: 1
                }}
              >
                <LogosWrapper>
                  <Svg icon={visitPortugalSvg} size={`clamp(165px,${clampCalc(165, 214)},214px)`} />

                  <Svg icon={fundacaoSaramagoSvg} size={`clamp(130px,${clampCalc(130, 166)},166px)`} />

                  <Svg icon={saramago100Svg} size={`clamp(144px,${clampCalc(144, 186)},186px)`} />
                </LogosWrapper>
              </FadeInUpAnimation>

              <FadeInUpAnimation
                options={{
                  distance: '100%',
                  transitionDelay: 0.4,
                  transitionTime: 1
                }}
              >
                <PreTitle>{translate(`homepage:sections.welcomeSection.preTitle`)}</PreTitle>

                <PreSubtitle>{translate(`homepage:sections.welcomeSection.preSubtitle`)}</PreSubtitle>
              </FadeInUpAnimation>

              <FadeInUpAnimation
                options={{
                  distance: '50%',
                  transitionDelay: 0.5,
                  transitionTime: 1
                }}
              >
                <Title as={'h2'} variant={'titleXL'}>
                  <TitleSmall as={'span'}>{translate(`homepage:sections.welcomeSection.title`)}</TitleSmall>

                  <TitleCountry as={'span'}>{'Portugal'}</TitleCountry>

                  <TitleHandWrite as={'span'} variant={'handwrittenM'}>
                    {translate(`homepage:sections.welcomeSection.subtitle`)}
                  </TitleHandWrite>
                </Title>
              </FadeInUpAnimation>

              <FadeInUpAnimation
                options={{
                  distance: '50%',
                  transitionDelay: 0.7,
                  transitionTime: 1
                }}
              >
                <StyledButton colorTheme={'primary'} href={routeResolver('tour')} variant={'outlined'}>
                  {isMobileSm
                    ? translate(`common:actions.startJourneyMobile`)
                    : translate(`common:actions.startJourney`)}
                </StyledButton>
              </FadeInUpAnimation>

              <FadeInAnimation
                options={{
                  transitionDelay: 0.8,
                  transitionTime: 1
                }}
              >
                <KnowMore>
                  <TriggerClick onClick={() => handleClick('saramago-section')} />

                  <KnowMoreText>{translate(`common:actions.knowMore`)}</KnowMoreText>
                </KnowMore>
              </FadeInAnimation>
            </>
          )}
        </Content>
      </StyledContainer>
    </Section>
  );
};

/**
 * Export `WelcomeSection` component.
 */

export default WelcomeSection;
